.icon {
  box-sizing: border-box;
  font-size: inherit;
  height: 1em;
  text-indent: -9999px;
  vertical-align: middle;
  width: 1em;
  font-style: normal;
  display: inline-block;
  position: relative;
}

.icon:after, .icon:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon.icon-2x {
  font-size: 1.6rem;
}

.icon.icon-3x {
  font-size: 2.4rem;
}

.icon.icon-4x {
  font-size: 3.2rem;
}

.accordion .icon, .btn .icon, .menu .icon, .toast .icon {
  vertical-align: -10%;
}

.btn-lg .icon {
  vertical-align: -15%;
}

.icon-arrow-down:before, .icon-arrow-left:before, .icon-arrow-right:before, .icon-arrow-up:before, .icon-back:before, .icon-downward:before, .icon-forward:before, .icon-upward:before {
  height: .65em;
  width: .65em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
}

.icon-arrow-down:before {
  transform: translate(-50%, -75%)rotate(225deg);
}

.icon-arrow-left:before {
  transform: translate(-25%, -50%)rotate(-45deg);
}

.icon-arrow-right:before {
  transform: translate(-75%, -50%)rotate(135deg);
}

.icon-arrow-up:before {
  transform: translate(-50%, -25%)rotate(45deg);
}

.icon-back:after, .icon-forward:after {
  height: .1rem;
  width: .8em;
  background: currentColor;
}

.icon-downward:after, .icon-upward:after {
  height: .8em;
  width: .1rem;
  background: currentColor;
}

.icon-back:after {
  left: 55%;
}

.icon-back:before {
  transform: translate(-50%, -50%)rotate(-45deg);
}

.icon-downward:after {
  top: 45%;
}

.icon-downward:before {
  transform: translate(-50%, -50%)rotate(-135deg);
}

.icon-forward:after {
  left: 45%;
}

.icon-forward:before {
  transform: translate(-50%, -50%)rotate(135deg);
}

.icon-upward:after {
  top: 55%;
}

.icon-upward:before {
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-caret:before {
  height: 0;
  width: 0;
  border-top: .3em solid;
  border-left: .3em solid #0000;
  border-right: .3em solid #0000;
  transform: translate(-50%, -25%);
}

.icon-menu:before {
  height: .1rem;
  width: 100%;
  background: currentColor;
  box-shadow: 0 -.35em, 0 .35em;
}

.icon-apps:before {
  height: 3px;
  width: 3px;
  background: currentColor;
  box-shadow: -.35em -.35em, -.35em 0, -.35em .35em, 0 -.35em, 0 .35em, .35em -.35em, .35em 0, .35em .35em;
}

.icon-resize-horiz:after, .icon-resize-horiz:before, .icon-resize-vert:after, .icon-resize-vert:before {
  height: .45em;
  width: .45em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
}

.icon-resize-horiz:before, .icon-resize-vert:before {
  transform: translate(-50%, -90%)rotate(45deg);
}

.icon-resize-horiz:after, .icon-resize-vert:after {
  transform: translate(-50%, -10%)rotate(225deg);
}

.icon-resize-horiz:before {
  transform: translate(-90%, -50%)rotate(-45deg);
}

.icon-resize-horiz:after {
  transform: translate(-10%, -50%)rotate(135deg);
}

.icon-more-horiz:before, .icon-more-vert:before {
  height: 3px;
  width: 3px;
  background: currentColor;
  border-radius: 50%;
  box-shadow: -.4em 0, .4em 0;
}

.icon-more-vert:before {
  box-shadow: 0 -.4em, 0 .4em;
}

.icon-cross:before, .icon-minus:before, .icon-plus:before {
  height: .1rem;
  width: 100%;
  background: currentColor;
}

.icon-cross:after, .icon-plus:after {
  height: 100%;
  width: .1rem;
  background: currentColor;
}

.icon-cross:before {
  width: 100%;
}

.icon-cross:after {
  height: 100%;
}

.icon-cross:after, .icon-cross:before {
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-check:before {
  height: .5em;
  width: .9em;
  border-top: 0;
  border-bottom: .1rem solid;
  border-left: .1rem solid;
  border-right: 0;
  transform: translate(-50%, -75%)rotate(-45deg);
}

.icon-stop {
  border: .1rem solid;
  border-radius: 50%;
}

.icon-stop:before {
  height: .1rem;
  width: 1em;
  background: currentColor;
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-shutdown {
  border: .1rem solid;
  border-top-color: #0000;
  border-radius: 50%;
}

.icon-shutdown:before {
  content: "";
  height: .5em;
  width: .1rem;
  background: currentColor;
  top: .1em;
}

.icon-refresh:before {
  height: 1em;
  width: 1em;
  border: .1rem solid;
  border-right-color: #0000;
  border-radius: 50%;
}

.icon-refresh:after {
  height: 0;
  width: 0;
  border: .2em solid;
  border-color: #0000 currentColor currentColor #0000;
  top: 20%;
  left: 80%;
}

.icon-search:before {
  height: .75em;
  width: .75em;
  border: .1rem solid;
  border-radius: 50%;
  top: 5%;
  left: 5%;
  transform: translate(0)rotate(45deg);
}

.icon-search:after {
  height: .1rem;
  width: .4em;
  background: currentColor;
  top: 80%;
  left: 80%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.icon-edit:before {
  height: .4em;
  width: .85em;
  border: .1rem solid;
  transform: translate(-40%, -60%)rotate(-45deg);
}

.icon-edit:after {
  height: 0;
  width: 0;
  border: .15em solid #0000;
  border-color: #0000 #0000 currentColor currentColor;
  top: 95%;
  left: 5%;
  transform: translate(0, -100%);
}

.icon-delete:before {
  height: .75em;
  width: .75em;
  border: .1rem solid;
  border-top: 0;
  border-bottom-left-radius: .1rem;
  border-bottom-right-radius: .1rem;
  top: 60%;
}

.icon-delete:after {
  height: .1rem;
  width: .5em;
  background: currentColor;
  top: .05rem;
  box-shadow: -.25em .2em, .25em .2em;
}

.icon-share {
  border-top: 0;
  border-bottom: .1rem solid;
  border-left: .1rem solid;
  border-right: 0;
  border-radius: .1rem;
}

.icon-share:before {
  height: .4em;
  width: .4em;
  border: .1rem solid;
  border-top: 0;
  border-left: 0;
  top: .25em;
  left: 100%;
  transform: translate(-125%, -50%)rotate(-45deg);
}

.icon-share:after {
  height: .5em;
  width: .6em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  border-radius: 75% 0;
}

.icon-flag:before {
  height: 1em;
  width: .1rem;
  background: currentColor;
  left: 15%;
}

.icon-flag:after {
  height: .65em;
  width: .8em;
  border: .1rem solid;
  border-left: 0;
  border-top-right-radius: .1rem;
  border-bottom-right-radius: .1rem;
  top: 35%;
  left: 60%;
}

.icon-bookmark:before {
  height: .9em;
  width: .8em;
  border: .1rem solid;
  border-bottom: 0;
  border-top-left-radius: .1rem;
  border-top-right-radius: .1rem;
}

.icon-bookmark:after {
  height: .5em;
  width: .5em;
  border-top: .1rem solid;
  border-bottom: 0;
  border-left: 0;
  border-right: .1rem solid;
  border-radius: .1rem;
  transform: translate(-50%, 35%)rotate(-45deg)skew(15deg, 15deg);
}

.icon-download, .icon-upload {
  border-bottom: .1rem solid;
}

.icon-download:before, .icon-upload:before {
  height: .5em;
  width: .5em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  transform: translate(-50%, -60%)rotate(-135deg);
}

.icon-download:after, .icon-upload:after {
  height: .6em;
  width: .1rem;
  background: currentColor;
  top: 40%;
}

.icon-upload:before {
  transform: translate(-50%, -60%)rotate(45deg);
}

.icon-upload:after {
  top: 50%;
}

.icon-copy:before {
  height: .8em;
  width: .8em;
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  border-radius: .1rem;
  top: 35%;
  left: 40%;
}

.icon-copy:after {
  height: .8em;
  width: .8em;
  border: .1rem solid;
  border-radius: .1rem;
  top: 60%;
  left: 60%;
}

.icon-time {
  border: .1rem solid;
  border-radius: 50%;
}

.icon-time:before {
  height: .4em;
  width: .1rem;
  background: currentColor;
  transform: translate(-50%, -75%);
}

.icon-time:after {
  height: .3em;
  transform-origin: 50% 90%;
  width: .1rem;
  background: currentColor;
  transform: translate(-50%, -75%)rotate(90deg);
}

.icon-mail:before {
  height: .8em;
  width: 1em;
  border: .1rem solid;
  border-radius: .1rem;
}

.icon-mail:after {
  height: .5em;
  width: .5em;
  border-top: 0;
  border-bottom: .1rem solid;
  border-left: .1rem solid;
  border-right: 0;
  transform: translate(-50%, -90%)rotate(-45deg)skew(10deg, 10deg);
}

.icon-people:before {
  height: .45em;
  width: .45em;
  border: .1rem solid;
  border-radius: 50%;
  top: 25%;
}

.icon-people:after {
  height: .4em;
  width: .9em;
  border: .1rem solid;
  border-radius: 50% 50% 0 0;
  top: 75%;
}

.icon-message {
  border: 0;
  border-top: .1rem solid;
  border-left: .1rem solid;
  border-radius: .1rem;
}

.icon-message:before {
  height: .8em;
  width: .7em;
  border: .1rem solid;
  border-top: 0;
  border-left: 0;
  border-bottom-right-radius: .1rem;
  top: 40%;
  left: 65%;
}

.icon-message:after {
  height: .3em;
  width: .1rem;
  background: currentColor;
  border-radius: .1rem;
  top: 100%;
  left: 10%;
  transform: translate(0, -90%)rotate(45deg);
}

.icon-photo {
  border: .1rem solid;
  border-radius: .1rem;
}

.icon-photo:before {
  height: .25em;
  width: .25em;
  border: .1rem solid;
  border-radius: 50%;
  top: 35%;
  left: 35%;
}

.icon-photo:after {
  height: .5em;
  width: .5em;
  border-top: .1rem solid;
  border-bottom: 0;
  border-left: 0;
  border-right: .1rem solid;
  left: 60%;
  transform: translate(-50%, 25%)rotate(-45deg);
}

.icon-link:after, .icon-link:before {
  height: .5em;
  width: .75em;
  border: .1rem solid;
  border-right: 0;
  border-radius: 5em 0 0 5em;
}

.icon-link:before {
  transform: translate(-70%, -45%)rotate(-45deg);
}

.icon-link:after {
  transform: translate(-30%, -55%)rotate(135deg);
}

.icon-location:before {
  height: .8em;
  width: .8em;
  border: .1rem solid;
  border-radius: 50% 50% 50% 0;
  transform: translate(-50%, -60%)rotate(-45deg);
}

.icon-location:after {
  height: .2em;
  width: .2em;
  border: .1rem solid;
  border-radius: 50%;
  transform: translate(-50%, -80%);
}

.icon-emoji {
  border: .1rem solid;
  border-radius: 50%;
}

.icon-emoji:before {
  height: .15em;
  width: .15em;
  border-radius: 50%;
  box-shadow: -.17em -.1em, .17em -.1em;
}

.icon-emoji:after {
  height: .5em;
  width: .5em;
  border: .1rem solid #0000;
  border-color: currentColor #0000 #0000 currentColor;
  border-radius: 50%;
  transform: translate(-50%, -40%)rotate(-135deg);
}

/*# sourceMappingURL=index.837ad830.css.map */
